import React from 'react';
import {
  ConditionsCollapsible,
  ContinuedTextListModule,
  FeaturedArticles,
  Hero,
  Module,
  SingleQuote,
  StatisticStructuredBox,
  Structured
} from '../../redesign';
import structuredContent from '../../redesign/content/digestion/structured-understand';
import heroContent from '../../redesign/content/digestion/digestion-hero.json';
import singleStatStructuredContent from '../../redesign/content/digestion/single-stat-structure.json';
import symptomsListContent from '../../redesign/content/digestion/symptoms-list.json';
import doctorsSuccessfullyTreatContent from '../../redesign/content/digestion/doctors-successfully-treat.json';
import quoteCarouselContent from '../../redesign/content/digestion/quote-carousel.json';
import blogPostContent from '../../redesign/content/digestion/blog-post.json';
import { CliniciansHalfAndHalf } from '../../redesign/pre-built-components';
import { default as SEO } from '../../components/SEO';
import { Layout } from '../../redesign';

export default function HormonalHealth() {
  return (
    <Layout>
      <SEO
        pageTitle="How to Improve Gut Health Holistically | Digestive Issues | Parsley Health"
        pageDescription="Parsley Health uses personalized health plans to improve gut health & get to the root cause of digestive issues. Our holistic medicine delivers transformational results."
      />
      <Module>
        <Hero {...heroContent} />
      </Module>
      <Module marginTop="lg">
        <StatisticStructuredBox {...singleStatStructuredContent} />
      </Module>
      <Module>
        <ContinuedTextListModule {...symptomsListContent} />
      </Module>
      <Module>
        <ConditionsCollapsible {...doctorsSuccessfullyTreatContent} />
      </Module>
      <Module>
        <Structured {...structuredContent} />
      </Module>
      <Module>
        <SingleQuote {...quoteCarouselContent} />
      </Module>
      <Module>
        <CliniciansHalfAndHalf ctaTrackingLabel="Meet Our Clinicians button on the Gut Health page - Stress-free online holistic care module" />
      </Module>
      <Module>
        <FeaturedArticles {...blogPostContent} />
      </Module>
    </Layout>
  );
}
