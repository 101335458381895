import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const Data: StructuredProps = {
  alignment: 'center',
  heading: 'How we treat digestive conditions.',
  items: [
    {
      id: 'deep-understanding',
      heading: 'We dive deep to understand your gut issues.',
      description:
        'Our clinicians take the time to learn about you, in person or virtually, with an in-depth assessment to understand how your health history, genetics, and lifestyle affect your digestive health and symptoms.',
      illustration: <IllustrativeIcon color="sage" name="personal" />
    },
    {
      id: 'advanced-testing',
      heading: 'Advanced testing to diagnose and treat.',
      description:
        'We use advanced testing, like breath tests and comprehensive stool analysis, to diagnose and treat IBS, SIBO, and other digestive conditions that conventional doctors often miss.',
      illustration: <IllustrativeIcon color="sage" name="testing" />
    },
    {
      id: 'personalized-plan',
      heading: 'A personalized plan for healing and relief',
      description:
        'Your care team optimizes your nutrition with dietary guidance, antimicrobial herbs, and probiotics. They also offer recommendations for improving sleep, managing stress, and adding movement to your routine.',
      illustration: <IllustrativeIcon color="sage" name="teamCollab" />
    }
  ]
};

export default Data;
